/* 全局CSS样式定义 */
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
}

/* 定义一些通用的样式，比如字体、颜色等 */
body {
  font-family: 'Helvetica Neue', sans-serif;
  background-color: #f4f4f4;
  color: #333;
  line-height: 1.6;
}

/* 可以添加更多的通用样式，供其他组件使用 */