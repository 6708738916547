/* 重置CSS */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* 全局样式 */
body {
  font-family: 'Arial', sans-serif;
  line-height: 1.6;
  background-color: #ffffff;
  color: #333;
  padding: 2rem;
}

/* 导航栏样式 */
.navbar {
  background: #3d3d3d;
  backdrop-filter: blur(10px);
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.navbar:hover {
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
}

.nav-logo {
  font-size: 1.5rem;
  font-weight: bold;
}

.nav-links ul {
  list-style: none;
  display: flex;
  gap: 1rem;
  align-items: center; 
}

.nav-links ul li {
  padding: 0.5rem;
}

.nav-links .nav-link {
  color: #ffffff;
  text-decoration: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  transition: background 0.3s, box-shadow 0.3s;
}

.nav-links .nav-link:hover {
  background: rgba(0, 123, 255, 0.3);
  box-shadow: 0 4px 8px rgba(0, 123, 255, 0.2);
}

/* 联系我们按钮样式 */
.contact-button {
  background: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  margin-left: auto; 
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
}

.contact-button:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

/* 主要内容区域 */
main {
  padding: 2rem;
  border-radius: 10px;
  background-color: #f4f4f4;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

main:hover {
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
}

/* 项目区样式 */
.projects {
  text-align: center;
}

.project-list {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  justify-content: center;
  margin-top: 2rem;
}

.project-card {
  width: calc(33.333% - 1rem);
  padding: 1rem;
  border-radius: 10px;
  background-color: #ffffff;
  text-align: left;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
}

.project-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.15);
}

.project-image {
  max-width: 100%;
  height: auto;
  border-radius: 4px;
  margin-bottom: 0.5rem;
}

.project-card h3 {
  margin-bottom: 0.5rem;
  color: #007bff;
}

.project-card a {
  display: inline-block;
  color: #007bff;
  text-decoration: none;
  margin-top: 0.5rem;
  transition: color 0.3s;
}

.project-card a:hover {
  color: #0056b3;
}

/* 响应式设计 */
@media (max-width: 768px) {
  .project-card {
    width: calc(50% - 1rem);
  }

  .nav-links ul {
    flex-direction: column;
    gap: 0.5rem;
  }
}

@media (max-width: 480px) {
  .project-card {
    width: 100%;
  }
}

/* 底部导航栏样式 */
.footer {
  background: #333;
  color: #fff;
  text-align: center;
  padding: 1rem 0;
  position: relative;
  bottom: 0;
  width: 100%;
}

/* 弹窗样式 */
.contact-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background: #fff;
  color: #333;
  padding: 20px;
  border-radius: 5px;
  width: 80%;
  max-width: 400px;
  text-align: center;
  position: relative;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.modal-header h2.modal-title {
  color: #333;
  margin: 0;
}

.modal-body p {
  color: #333;
}

.close-button {
  color: #333;
  font-size: 18px;
  cursor: pointer;
  background: transparent;
  border: none;
  position: absolute;
  top: 10px;
  right: 10px;
}
